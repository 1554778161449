import * as React from "react"
import { Link } from "gatsby"
import "./home.scss"

const HomePage: React.FC<{}> = () => {

  return (
    <div className="home-page">
      <img src={require("../assets/images/tedx-bath-logo.svg")} alt="TEDx Bath logo" className="tedx-bath-logo" />

      <div className="block">
        <img src={require("../assets/images/echoes-logo-white.svg")} alt="Echoes of the City logo" className="echoes-logo" />

          <Link to="/map" className="explore-btn">
            Click here to <span>Explore the City</span>
          </Link>

      </div>
      <div className="rocketmakers-logo-block">
        <span className="powered-by">Powered by:</span> 
        <a href="https://www.rocketmakers.com/">
        <img src={require("../assets/images/rocketmakers-logo-white.svg")} alt="Rocketmakers company logo" className="rocketmakers-logo" />
      </a>
      </div>
    </div>
  )
}

export default HomePage;